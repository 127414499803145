interface ProductDetails {
  id: string;
  name: string;
  description: string;
  benefits: string[];
  features: string[];
  ingredients?: string[];
  howToUse: string[];
  pros: string[];
  cons: string[];
  summary: string;
  rating: number;
  reviews: number;
  price: {
    amount: number;
    unit: string;
    discountPercentage?: number;
  };
  guaranteeDays: number;
  affiliateLink: string;
}

export const productDetails: Record<string, ProductDetails> = {
  "vigrx-plus": {
    id: "vigrx-plus",
    name: "VigRx Plus",
    description: "VigRX Plus is a natural male enhancement supplement designed to improve sexual performance, increase libido, and enhance erection quality.",
    benefits: [
      "Enhanced sexual performance",
      "Increased libido and sex drive",
      "Harder and longer-lasting erections",
      "Improved sexual satisfaction",
      "Better orgasm control"
    ],
    features: [
      "100% natural ingredients",
      "Clinically proven formula",
      "No prescription required",
      "Made in FDA-approved facility",
      "Third-party tested for quality"
    ],
    ingredients: [
      "Damiana",
      "Epimedium Leaf Extract",
      "Asian Red Ginseng",
      "Muira Puama",
      "Hawthorn Berry",
      "Catuaba Bark Extract",
      "Saw Palmetto",
      "Ginkgo Biloba",
      "Bioperine"
    ],
    howToUse: [
      "Take two tablets daily",
      "One tablet in the morning",
      "One tablet in the evening",
      "Take with meals for best absorption",
      "Maintain consistent usage for optimal results"
    ],
    pros: [
      "Clinically proven results",
      "Natural ingredients",
      "No reported side effects",
      "67-day money-back guarantee",
      "Positive customer reviews"
    ],
    cons: [
      "Premium pricing",
      "Results may vary",
      "Takes time to see full effects",
      "Only available online"
    ],
    summary: "VigRX Plus stands out as a premium male enhancement supplement with clinically proven results. Its natural formula and comprehensive approach to male sexual health make it a top choice for those seeking improved performance and satisfaction.",
    rating: 4.8,
    reviews: 1250,
    price: {
      amount: 69.95,
      unit: "USD",
      discountPercentage: 15
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.vigrxplus.com/ct/221426"
  },
  "testosil": {
    id: "testosil",
    name: "Testosil",
    description: "Testosil is a premium testosterone booster designed to naturally enhance male vitality, strength, and overall performance.",
    benefits: [
      "Increased testosterone levels",
      "Enhanced muscle growth",
      "Improved strength and stamina",
      "Better mood and energy",
      "Enhanced libido"
    ],
    features: [
      "Natural testosterone support",
      "Scientifically formulated",
      "Premium quality ingredients",
      "Made in GMP-certified facility",
      "Quality tested"
    ],
    ingredients: [
      "D-Aspartic Acid",
      "Fenugreek Extract",
      "Zinc",
      "Vitamin D3",
      "Boron",
      "Magnesium",
      "Ashwagandha"
    ],
    howToUse: [
      "Take three capsules daily",
      "Best taken with meals",
      "Use consistently for optimal results",
      "Cycle every 12 weeks",
      "Combine with regular exercise"
    ],
    pros: [
      "Natural formula",
      "Comprehensive testosterone support",
      "Money-back guarantee",
      "No prescription needed",
      "Positive user feedback"
    ],
    cons: [
      "Higher price point",
      "Multiple capsules daily",
      "Results vary by individual",
      "Online only purchase"
    ],
    summary: "Testosil offers a powerful natural approach to boosting testosterone levels and enhancing male vitality. Its premium formulation and quality ingredients make it a strong choice for men seeking to optimize their hormonal health.",
    rating: 4.7,
    reviews: 980,
    price: {
      amount: 69.99,
      unit: "USD",
      discountPercentage: 10
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.testosil.com/ct/221426"
  },
  "genf20-plus": {
    id: "genf20-plus",
    name: "GenF20 Plus",
    description: "GenF20 Plus is an advanced HGH releaser designed to naturally stimulate human growth hormone production for enhanced muscle growth and male vitality.",
    benefits: [
      "Increased muscle mass",
      "Enhanced recovery",
      "Improved energy levels",
      "Better sleep quality",
      "Enhanced sexual function"
    ],
    features: [
      "Clinically researched formula",
      "Pharmaceutical grade ingredients",
      "Enteric coated tablets",
      "Doctor recommended",
      "Made in USA"
    ],
    ingredients: [
      "L-Arginine",
      "L-Glutamine",
      "L-Glycine",
      "L-Lysine",
      "GABA",
      "Deer Antler Velvet",
      "Astragalus Root Extract",
      "Colostrum"
    ],
    howToUse: [
      "Take four tablets daily",
      "Two tablets in the morning",
      "Two tablets before bed",
      "Take consistently",
      "Use for at least 3 months"
    ],
    pros: [
      "Scientifically backed formula",
      "High-quality ingredients",
      "Money-back guarantee",
      "Positive clinical results",
      "No prescription needed"
    ],
    cons: [
      "Premium price point",
      "Multiple daily doses",
      "Takes time to see results",
      "Not available in stores"
    ],
    summary: "GenF20 Plus represents a breakthrough in natural HGH enhancement, offering a comprehensive solution for men seeking to optimize their hormonal health and physical performance.",
    rating: 4.6,
    reviews: 850,
    price: {
      amount: 69.95,
      unit: "USD",
      discountPercentage: 20
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.genf20muscle.com/ct/221426"
  },
  "semenax": {
    id: "semenax",
    name: "Semenax",
    description: "Semenax is a natural supplement designed to increase semen volume and enhance male fertility and sexual satisfaction.",
    benefits: [
      "Increased semen volume",
      "More intense orgasms",
      "Enhanced fertility",
      "Improved sexual pleasure",
      "Better ejaculatory control"
    ],
    features: [
      "All-natural formula",
      "Clinical studies support",
      "Doctor-approved",
      "Made in FDA-approved facility",
      "Quality tested ingredients"
    ],
    ingredients: [
      "Swedish Flower Pollen",
      "L-Arginine HCL",
      "L-Lysine",
      "Epimedium Sagittatum",
      "Zinc Oxide",
      "L-Carnitine",
      "Maca",
      "Pine Bark Extract"
    ],
    howToUse: [
      "Take four capsules daily",
      "Two capsules in the morning",
      "Two capsules in the evening",
      "Take with water",
      "Use consistently for best results"
    ],
    pros: [
      "Natural ingredients",
      "Clinically proven",
      "Money-back guarantee",
      "No prescription required",
      "Positive user reviews"
    ],
    cons: [
      "Higher price point",
      "Results take time",
      "Multiple daily doses",
      "Online purchase only"
    ],
    summary: "Semenax offers a comprehensive solution for men seeking to enhance their sexual experience and fertility through increased semen production and improved reproductive health.",
    rating: 4.7,
    reviews: 920,
    price: {
      amount: 59.95,
      unit: "USD",
      discountPercentage: 10
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.semenax.com/ct/221426"
  },
  "erectin-gummies": {
    id: "erectin-gummies",
    name: "Erectin Gummies",
    description: "Erectin Gummies provide a convenient and delicious way to enhance male sexual performance with natural ingredients in an easy-to-take gummy form.",
    benefits: [
      "Improved erectile function",
      "Enhanced libido",
      "Better sexual performance",
      "Increased pleasure",
      "Greater confidence"
    ],
    features: [
      "Delicious gummy form",
      "Natural ingredients",
      "Easy to take",
      "Fast absorption",
      "Travel-friendly"
    ],
    ingredients: [
      "L-Arginine",
      "Pomegranate Extract",
      "Korean Red Ginseng",
      "Maca Root",
      "Tribulus Terrestris",
      "Zinc",
      "Vitamin B6",
      "Vitamin B12"
    ],
    howToUse: [
      "Take two gummies daily",
      "Can be taken any time",
      "No water needed",
      "Maintain regular schedule",
      "Store in cool, dry place"
    ],
    pros: [
      "Great taste",
      "Convenient form",
      "Natural ingredients",
      "Easy to take",
      "Money-back guarantee"
    ],
    cons: [
      "Contains sugar",
      "Limited availability",
      "Premium pricing",
      "May melt in heat"
    ],
    summary: "Erectin Gummies offer a unique and enjoyable approach to male enhancement, combining effective natural ingredients in a convenient gummy form for those who prefer alternatives to traditional pills.",
    rating: 4.5,
    reviews: 750,
    price: {
      amount: 49.95,
      unit: "USD",
      discountPercentage: 15
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.erectingummies.com/ct/221426"
  },
  "dim-3x": {
    id: "dim-3x",
    name: "DIM 3X",
    description: "DIM 3X is an advanced estrogen metabolism support supplement designed to optimize hormonal balance and enhance male vitality.",
    benefits: [
      "Balanced estrogen levels",
      "Enhanced testosterone activity",
      "Improved muscle definition",
      "Better energy levels",
      "Optimal hormonal health"
    ],
    features: [
      "Clinically dosed DIM",
      "Enhanced absorption",
      "Research-backed formula",
      "Premium ingredients",
      "Quality tested"
    ],
    ingredients: [
      "DIM (Diindolylmethane)",
      "AstraGin®",
      "BioPerine®",
      "Vitamin E",
      "Vitamin D3"
    ],
    howToUse: [
      "Take one capsule daily",
      "Best taken with meals",
      "Use consistently",
      "Monitor results",
      "Adjust timing as needed"
    ],
    pros: [
      "Once-daily dosing",
      "High-quality ingredients",
      "Money-back guarantee",
      "Research-backed",
      "Made in USA"
    ],
    cons: [
      "Premium price",
      "Results vary",
      "Limited availability",
      "Online only"
    ],
    summary: "DIM 3X provides a scientifically-formulated solution for men seeking to optimize their hormonal balance through improved estrogen metabolism, supporting overall male health and vitality.",
    rating: 4.6,
    reviews: 680,
    price: {
      amount: 59.95,
      unit: "USD",
      discountPercentage: 10
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.dim3x.com/ct/221426"
  },
  "erectin-gel": {
    id: "erectin-gel",
    name: "Erectin Gel",
    description: "Erectin Gel is a fast-acting topical solution designed to enhance male sexual performance through improved blood flow and sensation.",
    benefits: [
      "Immediate enhancement",
      "Improved sensation",
      "Better blood flow",
      "Enhanced pleasure",
      "Increased confidence"
    ],
    features: [
      "Fast-acting formula",
      "Easy application",
      "Non-greasy",
      "Discreet packaging",
      "Travel-friendly"
    ],
    ingredients: [
      "L-Arginine",
      "Pomegranate Extract",
      "Aloe Vera",
      "Vitamin E",
      "Menthol",
      "Ginger Extract"
    ],
    howToUse: [
      "Apply as needed",
      "Use small amount",
      "Massage gently",
      "Wait 5-10 minutes",
      "Wash hands after use"
    ],
    pros: [
      "Immediate effects",
      "Easy to use",
      "Natural ingredients",
      "No pills needed",
      "Money-back guarantee"
    ],
    cons: [
      "Effects temporary",
      "May cause tingling",
      "Not for oral use",
      "Results vary"
    ],
    summary: "Erectin Gel provides a convenient and fast-acting topical solution for men seeking immediate enhancement of their sexual experience through improved blood flow and sensation.",
    rating: 4.4,
    reviews: 590,
    price: {
      amount: 49.95,
      unit: "USD",
      discountPercentage: 10
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.erectingel.com/ct/221426"
  },
  "testodren": {
    id: "testodren",
    name: "Testodren",
    description: "Testodren is a natural testosterone booster designed to enhance male vitality, strength, and overall performance through clinically proven ingredients.",
    benefits: [
      "Increased testosterone levels",
      "Enhanced muscle strength",
      "Improved energy",
      "Better mood",
      "Enhanced libido"
    ],
    features: [
      "Clinically proven formula",
      "Single ingredient potency",
      "Once daily dosing",
      "Premium quality",
      "Third-party tested"
    ],
    ingredients: [
      "PrimeGEN™",
      "Tribulus Terrestris",
      "Fenugreek Extract",
      "D-Aspartic Acid",
      "Zinc"
    ],
    howToUse: [
      "Take one capsule daily",
      "Best taken in morning",
      "Take with food",
      "Stay consistent",
      "Cycle as recommended"
    ],
    pros: [
      "Simple dosing",
      "Proven ingredients",
      "Money-back guarantee",
      "Natural formula",
      "Quality tested"
    ],
    cons: [
      "Premium pricing",
      "Online only",
      "Results vary",
      "Limited availability"
    ],
    summary: "Testodren offers a powerful and scientifically-backed approach to natural testosterone enhancement, helping men achieve optimal hormonal balance and performance.",
    rating: 4.5,
    reviews: 820,
    price: {
      amount: 59.95,
      unit: "USD",
      discountPercentage: 15
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.testodren.com/ct/221426"
  },
  "extenze": {
    id: "extenze",
    name: "Extenze",
    description: "Extenze is a fast-acting male enhancement supplement designed to improve sexual performance, stamina, and satisfaction through its proprietary blend of natural ingredients.",
    benefits: [
      "Enhanced performance",
      "Increased stamina",
      "Better erection quality",
      "Improved libido",
      "Greater pleasure"
    ],
    features: [
      "Quick-acting formula",
      "Natural ingredients",
      "Extended release",
      "Made in USA",
      "GMP certified"
    ],
    ingredients: [
      "Yohimbe Extract",
      "L-Arginine",
      "Tribulus Terrestris",
      "Korean Ginseng",
      "Horny Goat Weed",
      "DHEA"
    ],
    howToUse: [
      "Take one tablet daily",
      "Best taken before activity",
      "Stay hydrated",
      "Use consistently",
      "Follow directions carefully"
    ],
    pros: [
      "Fast acting",
      "Natural ingredients",
      "Well-known brand",
      "Money-back guarantee",
      "Easy to take"
    ],
    cons: [
      "Contains stimulants",
      "May cause side effects",
      "Results vary",
      "Premium price"
    ],
    summary: "Extenze provides a quick-acting solution for male enhancement, combining traditional herbs and modern science for improved sexual performance and satisfaction.",
    rating: 4.3,
    reviews: 950,
    price: {
      amount: 49.95,
      unit: "USD",
      discountPercentage: 10
    },
    guaranteeDays: 60,
    affiliateLink: "https://www.buyextenze.com/ct/221426"
  },
  "proextender": {
    id: "proextender",
    name: "ProExtender",
    description: "ProExtender is a medical-grade device designed for natural penile enhancement through controlled traction technology.",
    benefits: [
      "Natural enlargement",
      "Improved length",
      "Enhanced girth",
      "Better confidence",
      "Permanent results"
    ],
    features: [
      "Medical-grade materials",
      "Adjustable tension",
      "Comfortable design",
      "Discreet wearing",
      "Clinical backing"
    ],
    ingredients: null,
    howToUse: [
      "Start with 1-2 hours daily",
      "Gradually increase time",
      "Follow adjustment guide",
      "Clean regularly",
      "Monitor progress"
    ],
    pros: [
      "Clinically proven method",
      "Non-invasive",
      "Permanent results",
      "Medical-grade quality",
      "Money-back guarantee"
    ],
    cons: [
      "Requires commitment",
      "Takes time",
      "Higher initial cost",
      "Must be worn regularly"
    ],
    summary: "ProExtender offers a safe, medical-grade solution for natural enhancement through proven traction technology, providing permanent results with consistent use.",
    rating: 4.4,
    reviews: 480,
    price: {
      amount: 299.95,
      unit: "USD",
      discountPercentage: 20
    },
    guaranteeDays: 180,
    affiliateLink: "https://www.proextender.com/ct/221426"
  },
  "vigrx-nitric-oxide": {
    id: "vigrx-nitric-oxide",
    name: "VigRX Nitric Oxide",
    description: "VigRX Nitric Oxide is a specialized supplement designed to enhance blood flow and vascular health for improved male performance.",
    benefits: [
      "Enhanced blood flow",
      "Better erection quality",
      "Improved stamina",
      "Greater energy",
      "Better workout performance"
    ],
    features: [
      "L-Arginine formula",
      "Extended release",
      "Dual action support",
      "Quality tested",
      "Made in USA"
    ],
    ingredients: [
      "L-Arginine",
      "L-Citrulline",
      "Beetroot",
      "Pine Bark Extract",
      "Vitamin C",
      "Vitamin B12"
    ],
    howToUse: [
      "Take two tablets daily",
      "Morning and evening doses",
      "Take with water",
      "Use consistently",
      "Combine with exercise"
    ],
    pros: [
      "Natural formula",
      "Dual benefits",
      "Money-back guarantee",
      "Quality ingredients",
      "Research backed"
    ],
    cons: [
      "Premium pricing",
      "Two daily doses",
      "Results vary",
      "Online only"
    ],
    summary: "VigRX Nitric Oxide provides a comprehensive approach to male enhancement through improved blood flow and vascular health, supporting both sexual and athletic performance.",
    rating: 4.6,
    reviews: 730,
    price: {
      amount: 59.95,
      unit: "USD",
      discountPercentage: 15
    },
    guaranteeDays: 67,
    affiliateLink: "https://www.vigrxnitricoxide.com/ct/221426"
  }
};

export const getProductDetails = (productId: string): ProductDetails | undefined => {
  return productDetails[productId];
};