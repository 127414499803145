import { Link } from 'react-router-dom';
import { ArrowRight, Leaf, Shield, Beaker, Heart, CheckCircle2, AlertCircle } from 'lucide-react';
import { products } from '../data/products';

export default function Home() {
  const topProducts = products.slice(0, 3);

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              Natural Male Enhancement Supplements
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Expert reviews and recommendations for natural male enhancement solutions
            </p>
            <div className="flex justify-center space-x-4">
              <Link
                to="/reviews"
                className="inline-flex items-center bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
              >
                View All Reviews
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <a
                href={products[0].affiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center bg-indigo-600 text-white border-2 border-white px-8 py-3 rounded-lg font-semibold hover:bg-indigo-700 transition-colors"
              >
                Shop Best Seller
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Top-Rated Supplements Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
          Top-Rated Supplements
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {topProducts.map((product) => (
            <div key={product.name} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
              <img
                src={product.imageUrl}
                alt={product.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {product.name}
                </h3>
                <div className="flex items-center text-gray-600 mb-4">
                  <span className="font-medium text-indigo-600">
                    {product.rating}/5
                  </span>
                  <span className="mx-2">•</span>
                  <span>{product.reviews} reviews</span>
                </div>
                <div className="flex space-x-3">
                  <Link
                    to={`/reviews/${product.name.toLowerCase().replace(/\s+/g, '-')}`}
                    className="inline-flex items-center text-indigo-600 hover:text-indigo-700"
                  >
                    Read Review
                    <ArrowRight className="ml-1 h-4 w-4" />
                  </Link>
                  <a
                    href={product.affiliateLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
                  >
                    Visit Website
                    <ArrowRight className="ml-1 h-4 w-4" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Natural Male Enhancement Information Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Understanding Natural Male Enhancement</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Natural male enhancement supplements offer a safe, scientifically-supported approach to improving male sexual health and performance.
            </p>
          </div>

          {/* What Are Natural Male Enhancement Supplements */}
          <div className="grid md:grid-cols-2 gap-12 mb-16">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">What Are Natural Male Enhancement Supplements?</h3>
              <p className="text-gray-600 leading-relaxed mb-6">
                Natural male enhancement supplements are carefully formulated products that combine herbs, vitamins, minerals, and other natural ingredients 
                traditionally used to support male sexual health. These supplements are designed to work with your body's natural processes to enhance 
                various aspects of male sexual performance and overall vitality.
              </p>
              <div className="space-y-3">
                {['Clinically tested ingredients', 'Non-prescription solutions', 'Long-term health benefits', 'Minimal side effects'].map((point) => (
                  <div key={point} className="flex items-start">
                    <CheckCircle2 className="h-5 w-5 text-green-500 mt-1 mr-2" />
                    <span className="text-gray-700">{point}</span>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">How Do They Work?</h3>
              <div className="space-y-6">
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <h4 className="font-semibold text-gray-900 mb-2">Blood Flow Enhancement</h4>
                  <p className="text-gray-600">Improves circulation to enhance erectile function and performance</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <h4 className="font-semibold text-gray-900 mb-2">Hormone Balance</h4>
                  <p className="text-gray-600">Supports healthy testosterone levels and hormonal balance</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <h4 className="font-semibold text-gray-900 mb-2">Libido Support</h4>
                  <p className="text-gray-600">Enhances natural desire and sexual drive</p>
                </div>
              </div>
            </div>
          </div>

          {/* Key Benefits Section */}
          <div className="mb-16">
            <h3 className="text-2xl font-bold text-gray-900 mb-8 text-center">Key Benefits</h3>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-xl shadow-sm">
                <Leaf className="h-12 w-12 text-green-500 mb-4" />
                <h4 className="text-xl font-semibold text-gray-900 mb-2">Natural Ingredients</h4>
                <p className="text-gray-600">Safe, botanical ingredients with proven traditional use and modern scientific backing</p>
              </div>
              <div className="bg-white p-6 rounded-xl shadow-sm">
                <Shield className="h-12 w-12 text-blue-500 mb-4" />
                <h4 className="text-xl font-semibold text-gray-900 mb-2">Long-term Health</h4>
                <p className="text-gray-600">Supports overall male health while improving sexual performance</p>
              </div>
              <div className="bg-white p-6 rounded-xl shadow-sm">
                <Beaker className="h-12 w-12 text-purple-500 mb-4" />
                <h4 className="text-xl font-semibold text-gray-900 mb-2">Scientifically Studied</h4>
                <p className="text-gray-600">Ingredients backed by clinical research and scientific studies</p>
              </div>
            </div>
          </div>

          {/* Important Considerations */}
          <div className="bg-white p-8 rounded-xl shadow-sm">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Important Considerations</h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="flex items-start">
                  <AlertCircle className="h-5 w-5 text-amber-500 mt-1 mr-2" />
                  <div>
                    <h4 className="font-semibold text-gray-900 mb-1">Consistency is Key</h4>
                    <p className="text-gray-600">Results typically develop over time with regular use</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <AlertCircle className="h-5 w-5 text-amber-500 mt-1 mr-2" />
                  <div>
                    <h4 className="font-semibold text-gray-900 mb-1">Quality Matters</h4>
                    <p className="text-gray-600">Choose supplements from reputable manufacturers with quality testing</p>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex items-start">
                  <AlertCircle className="h-5 w-5 text-amber-500 mt-1 mr-2" />
                  <div>
                    <h4 className="font-semibold text-gray-900 mb-1">Individual Results</h4>
                    <p className="text-gray-600">Effects can vary based on individual health factors</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <AlertCircle className="h-5 w-5 text-amber-500 mt-1 mr-2" />
                  <div>
                    <h4 className="font-semibold text-gray-900 mb-1">Lifestyle Factors</h4>
                    <p className="text-gray-600">Best results when combined with healthy lifestyle choices</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}