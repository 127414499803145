import { Link } from 'react-router-dom';
import { Home as HomeIcon, FileText } from 'lucide-react';

export default function Navigation() {
  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center py-4">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h1 className="text-2xl font-bold text-gray-900">Natural Male Enhancement Supplements Reviews</h1>
          </div>
          <div className="flex space-x-8">
            <Link to="/" className="flex items-center px-2 py-2 text-gray-700 hover:text-gray-900">
              <HomeIcon className="h-6 w-6" />
              <span className="ml-2 font-medium">Home</span>
            </Link>
            <Link to="/reviews" className="flex items-center px-2 py-2 text-gray-700 hover:text-gray-900">
              <FileText className="h-6 w-6" />
              <span className="ml-2 font-medium">Reviews</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}