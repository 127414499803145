import { Link } from 'react-router-dom';
import { ChevronRight, Home, FileText, Users, Mail, Search, BookOpen } from 'lucide-react';
import { products } from '../data/products';
import { blogPosts } from '../data/blogPosts';

export default function Sitemap() {
  return (
    <div className="min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Sitemap</h1>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Main Pages */}
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
              <Home className="h-5 w-5 mr-2 text-indigo-600" />
              Main Pages
            </h2>
            <ul className="space-y-3">
              <li>
                <Link to="/" className="flex items-center text-gray-600 hover:text-indigo-600">
                  <ChevronRight className="h-4 w-4 mr-2" />
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="flex items-center text-gray-600 hover:text-indigo-600">
                  <ChevronRight className="h-4 w-4 mr-2" />
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="flex items-center text-gray-600 hover:text-indigo-600">
                  <ChevronRight className="h-4 w-4 mr-2" />
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Products */}
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
              <Search className="h-5 w-5 mr-2 text-indigo-600" />
              Products
            </h2>
            <ul className="space-y-3">
              <li>
                <Link to="/products" className="flex items-center text-gray-600 hover:text-indigo-600">
                  <ChevronRight className="h-4 w-4 mr-2" />
                  All Products
                </Link>
              </li>
              {products.map((product) => (
                <li key={product.name}>
                  <Link 
                    to={`/reviews/${product.name.toLowerCase().replace(/\s+/g, '-')}`}
                    className="flex items-center text-gray-600 hover:text-indigo-600"
                  >
                    <ChevronRight className="h-4 w-4 mr-2" />
                    {product.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Blog Posts */}
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
              <BookOpen className="h-5 w-5 mr-2 text-indigo-600" />
              Blog Posts
            </h2>
            <ul className="space-y-3">
              <li>
                <Link to="/blog" className="flex items-center text-gray-600 hover:text-indigo-600">
                  <ChevronRight className="h-4 w-4 mr-2" />
                  All Articles
                </Link>
              </li>
              {blogPosts.slice(0, 10).map((post) => (
                <li key={post.id}>
                  <Link 
                    to={`/blog/${post.id}`}
                    className="flex items-center text-gray-600 hover:text-indigo-600"
                  >
                    <ChevronRight className="h-4 w-4 mr-2" />
                    {post.title.length > 40 ? `${post.title.substring(0, 40)}...` : post.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Reviews */}
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
              <FileText className="h-5 w-5 mr-2 text-indigo-600" />
              Reviews
            </h2>
            <ul className="space-y-3">
              <li>
                <Link to="/reviews" className="flex items-center text-gray-600 hover:text-indigo-600">
                  <ChevronRight className="h-4 w-4 mr-2" />
                  All Reviews
                </Link>
              </li>
              {products.slice(0, 5).map((product) => (
                <li key={product.name}>
                  <Link 
                    to={`/reviews/${product.name.toLowerCase().replace(/\s+/g, '-')}`}
                    className="flex items-center text-gray-600 hover:text-indigo-600"
                  >
                    <ChevronRight className="h-4 w-4 mr-2" />
                    {product.name} Review
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Support */}
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
              <Users className="h-5 w-5 mr-2 text-indigo-600" />
              Support
            </h2>
            <ul className="space-y-3">
              <li>
                <Link to="/contact" className="flex items-center text-gray-600 hover:text-indigo-600">
                  <ChevronRight className="h-4 w-4 mr-2" />
                  Contact Support
                </Link>
              </li>
              <li>
                <Link to="/about" className="flex items-center text-gray-600 hover:text-indigo-600">
                  <ChevronRight className="h-4 w-4 mr-2" />
                  About Our Reviews
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}