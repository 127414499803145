import { Search } from 'lucide-react';

export default function SearchBar() {
  return (
    <div className="max-w-3xl w-full">
      <div className="relative">
        <input
          type="text"
          placeholder="Search for products..."
          className="w-full px-4 py-3 pl-12 text-gray-900 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
        />
        <Search className="absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
      </div>
    </div>
  );
}