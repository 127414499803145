import { Star, ThumbsUp, MessageCircle, ArrowRight, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';
import { products } from '../data/products';

interface ProductCardProps {
  name: string;
  imageUrl: string;
  rating: number;
  reviews: number;
}

export default function ProductCard({ name, imageUrl, rating, reviews }: ProductCardProps) {
  const product = products.find(p => p.name === name);
  const affiliateLink = product?.affiliateLink;

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/3">
          <img
            src={imageUrl}
            alt={name}
            className="w-full h-64 object-cover"
          />
        </div>
        <div className="p-6 md:w-2/3">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            {name}
          </h2>
          <div className="flex items-center mb-4">
            <div className="flex items-center">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  className={`h-5 w-5 ${
                    i < Math.floor(rating)
                      ? 'text-yellow-400 fill-current'
                      : 'text-gray-300'
                  }`}
                />
              ))}
            </div>
            <span className="ml-2 text-sm text-gray-600">
              ({reviews} reviews)
            </span>
          </div>
          <div className="flex items-center space-x-6 text-gray-600 mb-6">
            <div className="flex items-center">
              <ThumbsUp className="h-5 w-5 mr-2" />
              <span>98% Recommended</span>
            </div>
            <div className="flex items-center">
              <MessageCircle className="h-5 w-5 mr-2" />
              <span>{reviews} Comments</span>
            </div>
          </div>
          <div className="flex space-x-4">
            <Link
              to={`/reviews/${name.toLowerCase().replace(/\s+/g, '-')}`}
              className="inline-flex items-center text-indigo-600 hover:text-indigo-700 font-semibold"
            >
              Read Full Review
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            {affiliateLink && (
              <a
                href={affiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
              >
                Visit Website
                <ExternalLink className="ml-2 h-5 w-5" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}