export interface Product {
  name: string;
  websiteUrl: string;
  affiliateLink: string;
  imageUrl: string;
  rating: number;
  reviews: number;
  description: string;
}

export const products: Product[] = [
  {
    name: "VigRx Plus",
    websiteUrl: "https://www.vigrxplus.com/",
    affiliateLink: "https://www.vigrxplus.com/ct/221426",
    imageUrl: "https://www.vigrxplus.com/wp-content/uploads/2024/08/hero-prod.png",
    rating: 4.8,
    reviews: 1250,
    description: "A natural male enhancement supplement designed to improve sexual performance and satisfaction."
  },
  {
    name: "Testosil",
    websiteUrl: "https://www.testosil.com/",
    affiliateLink: "https://www.testosil.com/ct/221426",
    imageUrl: "https://www.testosil.com/wp-content/uploads/2023/03/fold-bg.png",
    rating: 4.7,
    reviews: 980,
    description: "Premium testosterone booster for enhanced male vitality and performance."
  },
  {
    name: "GenF20 Plus",
    websiteUrl: "https://www.genf20muscle.com/",
    affiliateLink: "https://www.genf20muscle.com/ct/221426",
    imageUrl: "https://www.genf20muscle.com/wp-content/uploads/2020/11/onewaytoboostyourhghlevels.png",
    rating: 4.6,
    reviews: 850,
    description: "HGH releaser designed to boost muscle growth and enhance male performance."
  },
  {
    name: "Semenax",
    websiteUrl: "https://www.semenax.com/",
    affiliateLink: "https://www.semenax.com/ct/221426",
    imageUrl: "https://www.semenax.com/wp-content/uploads/2024/02/semenax-banner-prod.png",
    rating: 4.7,
    reviews: 920,
    description: "Natural supplement for increased semen volume and improved male fertility."
  },
  {
    name: "Erectin Gummies",
    websiteUrl: "https://www.erectingummies.com/",
    affiliateLink: "https://www.erectingummies.com/ct/221426",
    imageUrl: "https://www.erectingummies.com/wp-content/uploads/2023/12/gummies-fold-prod.png",
    rating: 4.5,
    reviews: 750,
    description: "Convenient gummy form supplement for improved erectile function."
  },
  {
    name: "DIM 3X",
    websiteUrl: "https://www.dim3x.com/",
    affiliateLink: "https://www.dim3x.com/ct/221426",
    imageUrl: "https://www.dim3x.com/wp-content/uploads/2023/05/67-days-guarantee.jpg",
    rating: 4.6,
    reviews: 680,
    description: "Advanced estrogen metabolism support for optimal hormonal balance."
  },
  {
    name: "Erectin Gel",
    websiteUrl: "https://www.erectingel.com/",
    affiliateLink: "https://www.erectingel.com/ct/221426",
    imageUrl: "https://www.erectingel.com/wp-content/uploads/2021/06/prod-img1.png",
    rating: 4.4,
    reviews: 590,
    description: "Topical gel for immediate enhancement and improved sensation."
  },
  {
    name: "Testodren",
    websiteUrl: "https://www.testodren.com/",
    affiliateLink: "https://www.testodren.com/ct/221426",
    imageUrl: "https://www.testodren.com/wp-content/uploads/2023/01/prod-img1.png",
    rating: 4.5,
    reviews: 820,
    description: "Natural testosterone booster for improved strength and vitality."
  },
  {
    name: "Extenze",
    websiteUrl: "https://www.buyextenze.com/",
    affiliateLink: "https://www.buyextenze.com/ct/221426",
    imageUrl: "https://www.buyextenze.com/wp-content/uploads/2024/09/prod-img1-1.png",
    rating: 4.3,
    reviews: 950,
    description: "Fast-acting male enhancement supplement for improved performance."
  },
  {
    name: "ProExtender",
    websiteUrl: "https://www.proextender.com/",
    affiliateLink: "https://www.proextender.com/ct/221426",
    imageUrl: "https://www.proextender.com/wp-content/uploads/2023/07/home-banner-prod-2.png",
    rating: 4.4,
    reviews: 480,
    description: "Medical-grade device for natural penile enhancement."
  },
  {
    name: "VigRX Nitric Oxide",
    websiteUrl: "https://www.vigrxnitricoxide.com/",
    affiliateLink: "https://www.vigrxnitricoxide.com/ct/221426",
    imageUrl: "https://www.vigrxnitricoxide.com/wp-content/uploads/2020/05/guarantee-vigrx-nitric-bottle-glow.png",
    rating: 4.6,
    reviews: 730,
    description: "Nitric oxide booster for improved blood flow and performance."
  }
];