import { useParams } from 'react-router-dom';
import { Star, ThumbsUp, MessageCircle, ExternalLink } from 'lucide-react';
import { products } from '../data/products';
import { getProductDetails } from '../data/productDetails';
import ProductReviewDetails from '../components/ProductReviewDetails';
import { Helmet } from 'react-helmet';

export default function ProductReview() {
  const { productId } = useParams();
  const product = products.find(
    (p) => p.name.toLowerCase().replace(/\s+/g, '-') === productId
  );
  const details = productId ? getProductDetails(productId) : undefined;

  if (!product || !details) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Product Not Found</h1>
          <p className="text-gray-600">The product you're looking for doesn't exist.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${product.name} Review - Natural Male Enhancement Supplement | Vigor Health Source`}</title>
        <meta name="description" content={`Detailed review of ${product.name}. Learn about benefits, ingredients, side effects, and real user experiences. Make an informed decision about this male enhancement supplement.`} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://vigorhealthsource.com/reviews/${productId}`} />
        <meta property="og:title" content={`${product.name} Review - Natural Male Enhancement Supplement`} />
        <meta property="og:description" content={`Detailed review of ${product.name}. Learn about benefits, ingredients, side effects, and real user experiences.`} />
        <meta property="og:url" content={`https://vigorhealthsource.com/reviews/${productId}`} />
        <meta property="og:type" content="article" />
      </Helmet>

      <div className="min-h-screen py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            {/* Hero Section */}
            <div className="md:flex">
              <div className="md:w-1/2">
                <img
                  src={product.imageUrl}
                  alt={product.name}
                  className="w-full h-96 object-cover"
                />
              </div>
              <div className="p-8 md:w-1/2">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  {product.name} Review
                </h1>
                
                <div className="flex items-center mb-6">
                  <div className="flex items-center">
                    {[...Array(5)].map((_, i) => (
                      <Star
                        key={i}
                        className={`h-6 w-6 ${
                          i < Math.floor(details.rating)
                            ? 'text-yellow-400 fill-current'
                            : 'text-gray-300'
                        }`}
                      />
                    ))}
                  </div>
                  <span className="ml-2 text-lg text-gray-600">
                    ({details.reviews} reviews)
                  </span>
                </div>

                <div className="space-y-4 mb-8">
                  <div className="flex items-center text-gray-600">
                    <ThumbsUp className="h-5 w-5 mr-2" />
                    <span>98% Recommended by users</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <MessageCircle className="h-5 w-5 mr-2" />
                    <span>{details.reviews} Verified Reviews</span>
                  </div>
                </div>

                <a
                  href={product.affiliateLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center bg-indigo-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-indigo-700 transition-colors"
                >
                  Visit Official Website
                  <ExternalLink className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>

            {/* Detailed Review Content */}
            <div className="p-8 border-t border-gray-200">
              <ProductReviewDetails details={details} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}