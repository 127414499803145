import { products } from '../data/products';
import ProductCard from '../components/ProductCard';

export default function Reviews() {
  return (
    <div className="min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Product Reviews</h1>
          <p className="text-xl text-gray-600">
            Detailed reviews and analysis of our top-rated products
          </p>
        </div>

        <div className="grid gap-8">
          {products.map((product) => (
            <ProductCard
              key={product.name}
              name={product.name}
              imageUrl={product.imageUrl}
              rating={product.rating}
              reviews={product.reviews}
            />
          ))}
        </div>
      </div>
    </div>
  );
}