import { useState } from 'react';
import { SlidersHorizontal } from 'lucide-react';
import SearchBar from '../components/SearchBar';
import ProductGrid from '../components/ProductGrid';
import { products } from '../data/products';

export default function Products() {
  const [sortBy, setSortBy] = useState('rating');

  const sortedProducts = [...products].sort((a, b) => {
    if (sortBy === 'rating') return b.rating - a.rating;
    if (sortBy === 'reviews') return b.reviews - a.reviews;
    return 0;
  });

  return (
    <div className="min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">All Products</h1>
          <div className="flex flex-col sm:flex-row gap-4 items-center">
            <div className="w-full sm:w-2/3">
              <SearchBar />
            </div>
            <div className="w-full sm:w-1/3">
              <div className="flex items-center gap-2">
                <SlidersHorizontal className="h-5 w-5 text-gray-400" />
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="rating">Sort by Rating</option>
                  <option value="reviews">Sort by Reviews</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <ProductGrid products={sortedProducts} />
      </div>
    </div>
  );
}