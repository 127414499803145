import { Product } from '../types';
import ProductCard from './ProductCard';

interface ProductGridProps {
  products: (Product & { rating: number; reviews: number })[];
}

export default function ProductGrid({ products }: ProductGridProps) {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      {products.map((product) => (
        <ProductCard key={product.name} product={product} />
      ))}
    </div>
  );
}