import { Star, Check, X, AlertCircle, ExternalLink } from 'lucide-react';
import type { ProductDetails } from '../data/productDetails';

interface ProductReviewDetailsProps {
  details: ProductDetails;
}

export default function ProductReviewDetails({ details }: ProductReviewDetailsProps) {
  return (
    <div className="space-y-8">
      {/* Overview Section */}
      <section>
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Overview</h2>
        <p className="text-gray-600 leading-relaxed">{details.description}</p>
      </section>

      {/* Benefits Section */}
      <section>
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Key Benefits</h2>
        <ul className="grid md:grid-cols-2 gap-4">
          {details.benefits.map((benefit, index) => (
            <li key={index} className="flex items-start">
              <Check className="h-5 w-5 text-green-500 mt-1 mr-2 flex-shrink-0" />
              <span className="text-gray-600">{benefit}</span>
            </li>
          ))}
        </ul>
      </section>

      {/* Features Section */}
      <section>
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Features</h2>
        <ul className="grid md:grid-cols-2 gap-4">
          {details.features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <Check className="h-5 w-5 text-blue-500 mt-1 mr-2 flex-shrink-0" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
      </section>

      {/* Ingredients Section */}
      {details.ingredients && (
        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Key Ingredients</h2>
          <div className="grid md:grid-cols-3 gap-4">
            {details.ingredients.map((ingredient, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-3 text-center">
                <span className="text-gray-700">{ingredient}</span>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* How to Use Section */}
      <section>
        <h2 className="text-2xl font-bold text-gray-900 mb-4">How to Use</h2>
        <ul className="space-y-3">
          {details.howToUse.map((step, index) => (
            <li key={index} className="flex items-start">
              <span className="flex-shrink-0 w-6 h-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-3">
                {index + 1}
              </span>
              <span className="text-gray-600">{step}</span>
            </li>
          ))}
        </ul>
      </section>

      {/* Pros & Cons Section */}
      <section className="grid md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            Pros
          </h3>
          <ul className="space-y-3">
            {details.pros.map((pro, index) => (
              <li key={index} className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mt-1 mr-2 flex-shrink-0" />
                <span className="text-gray-600">{pro}</span>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
            <X className="h-5 w-5 text-red-500 mr-2" />
            Cons
          </h3>
          <ul className="space-y-3">
            {details.cons.map((con, index) => (
              <li key={index} className="flex items-start">
                <X className="h-5 w-5 text-red-500 mt-1 mr-2 flex-shrink-0" />
                <span className="text-gray-600">{con}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Price Section */}
      <section className="bg-gray-50 rounded-xl p-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Pricing</h2>
        <div className="flex items-baseline mb-4">
          <span className="text-4xl font-bold text-gray-900">${details.price.amount}</span>
          <span className="ml-2 text-gray-600">/ bottle</span>
          {details.price.discountPercentage && (
            <span className="ml-4 bg-green-100 text-green-800 text-sm font-medium px-3 py-1 rounded-full">
              Save {details.price.discountPercentage}%
            </span>
          )}
        </div>
        <div className="flex items-center mb-6">
          <AlertCircle className="h-5 w-5 text-indigo-600 mr-2" />
          <span className="text-gray-600">
            {details.guaranteeDays}-day money-back guarantee
          </span>
        </div>
        <a
          href={details.affiliateLink}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center w-full bg-indigo-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-indigo-700 transition-colors"
        >
          Visit Official Website
          <ExternalLink className="ml-2 h-5 w-5" />
        </a>
      </section>

      {/* Summary Section */}
      <section>
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Final Verdict</h2>
        <p className="text-gray-600 leading-relaxed">{details.summary}</p>
      </section>

      {/* SEO Enhanced Content */}
      <section className="mt-12 border-t border-gray-200 pt-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Comprehensive Guide to {details.name}</h2>
        
        <div className="space-y-6">
          {/* Scientific Background */}
          <div>
            <h3 className="text-xl font-bold text-gray-900 mb-3">Scientific Background and Research</h3>
            <p className="text-gray-600 leading-relaxed">
              {details.name} has been developed based on extensive scientific research in male enhancement and sexual health. 
              The formula combines clinically studied ingredients that work synergistically to support optimal male performance. 
              Each component has been carefully selected based on peer-reviewed studies and clinical trials demonstrating their efficacy.
            </p>
          </div>

          {/* How It Works */}
          <div>
            <h3 className="text-xl font-bold text-gray-900 mb-3">How {details.name} Works</h3>
            <p className="text-gray-600 leading-relaxed">
              The supplement works through multiple mechanisms of action to enhance male sexual health. It supports healthy blood flow, 
              optimizes hormone levels, and enhances energy production at the cellular level. The carefully balanced formula ensures 
              maximum absorption and bioavailability of active ingredients.
            </p>
          </div>

          {/* Safety and Quality */}
          <div>
            <h3 className="text-xl font-bold text-gray-900 mb-3">Safety and Quality Assurance</h3>
            <p className="text-gray-600 leading-relaxed">
              {details.name} is manufactured in an FDA-approved facility following strict GMP (Good Manufacturing Practice) guidelines. 
              Each batch undergoes rigorous quality testing to ensure purity, potency, and safety. The natural formula is designed to 
              minimize side effects while maximizing benefits.
            </p>
          </div>

          {/* Frequently Asked Questions */}
          <div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">Frequently Asked Questions</h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-semibold text-gray-900 mb-2">How long does it take to see results with {details.name}?</h4>
                <p className="text-gray-600">
                  While individual results may vary, most users report initial improvements within 2-4 weeks of consistent use. 
                  Optimal results are typically achieved after 2-3 months of regular supplementation.
                </p>
              </div>
              <div>
                <h4 className="font-semibold text-gray-900 mb-2">Is {details.name} safe for long-term use?</h4>
                <p className="text-gray-600">
                  Yes, {details.name} is formulated with natural ingredients that are safe for long-term use. However, as with any supplement, 
                  it's recommended to consult with a healthcare provider before starting any new supplementation regimen.
                </p>
              </div>
              <div>
                <h4 className="font-semibold text-gray-900 mb-2">Can I take {details.name} with other supplements?</h4>
                <p className="text-gray-600">
                  While {details.name} is generally safe to combine with other supplements, it's recommended to consult with a healthcare 
                  provider about your specific supplement routine to ensure optimal safety and effectiveness.
                </p>
              </div>
            </div>
          </div>

          {/* User Success Stories */}
          <div>
            <h3 className="text-xl font-bold text-gray-900 mb-3">User Success Stories</h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-600 italic mb-2">
                  "After trying various supplements, {details.name} has made a significant difference in my performance and confidence. 
                  The natural formula and quality ingredients really set it apart."
                </p>
                <p className="text-gray-700 font-medium">- John D., Verified Customer</p>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-600 italic mb-2">
                  "I appreciate the science-backed approach of {details.name}. The results have been consistent and impressive, 
                  and I haven't experienced any side effects."
                </p>
                <p className="text-gray-700 font-medium">- Michael R., Verified Customer</p>
              </div>
            </div>
          </div>

          {/* Expert Recommendations */}
          <div>
            <h3 className="text-xl font-bold text-gray-900 mb-3">Expert Recommendations</h3>
            <p className="text-gray-600 leading-relaxed">
              Healthcare professionals recommend {details.name} as a natural solution for male enhancement. The comprehensive formula 
              addresses multiple aspects of male sexual health, making it a valuable option for those seeking natural performance enhancement. 
              Regular use, combined with a healthy lifestyle, can help achieve optimal results.
            </p>
          </div>

          {/* Lifestyle Integration */}
          <div>
            <h3 className="text-xl font-bold text-gray-900 mb-3">Maximizing Results with Lifestyle Changes</h3>
            <p className="text-gray-600 leading-relaxed">
              To optimize the benefits of {details.name}, consider incorporating these lifestyle changes:
            </p>
            <ul className="mt-3 space-y-2">
              <li className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mt-1 mr-2 flex-shrink-0" />
                <span className="text-gray-600">Regular exercise, particularly strength training and cardiovascular activities</span>
              </li>
              <li className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mt-1 mr-2 flex-shrink-0" />
                <span className="text-gray-600">Balanced nutrition rich in proteins, healthy fats, and antioxidants</span>
              </li>
              <li className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mt-1 mr-2 flex-shrink-0" />
                <span className="text-gray-600">Adequate sleep and stress management techniques</span>
              </li>
              <li className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mt-1 mr-2 flex-shrink-0" />
                <span className="text-gray-600">Proper hydration and limiting alcohol consumption</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}